import { FC, useState } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import { CommonWorkPlaceBody, WorkPlaceDetailed } from '@models/workPlace'
import {
  useCreateWorkPlaceMutation,
  useUpdateWorkPlaceMutation,
} from '@services/workPlaces'
import Tabs, { TabsItem } from '@organs/Tabs'
import AddNewItemModalLayout from '@templates/AddNewItemModalLayout'
import { TopTabsWrapper } from '@modals/RiskMapModal/styled'
import { hasValidationErrors } from '@utils/validation'
import Main from '@modals/WorkPlaceModal/Main'
import Classes from '@modals/WorkPlaceModal/Classes'
import Warranties from '@modals/WorkPlaceModal/Warranties'
import getDefaultValues from './getDefaultValues'

export type FormValues = any

type Props = {
  isEdit?: boolean
  workPlace?: WorkPlaceDetailed
  handleCloseModal: () => void
}

// TODO
const WorkPlaceModal: FC<Props> = ({
  isEdit = false,
  workPlace,
  handleCloseModal,
}) => {
  const [active, setActive] = useState(0)

  const [post, postData] = useCreateWorkPlaceMutation()
  const [put, putData] = useUpdateWorkPlaceMutation()
  const additionalProps = isEdit ? putData : postData

  const methods = useFormProcessor({
    apiErrors: additionalProps.error,
    defaultValues: getDefaultValues(workPlace),
  })

  const handleSubmitForm = (form: FormValues) => {
    console.log(form)
    const commonForm: CommonWorkPlaceBody = {
      title: 'qq',
    }

    if (isEdit) {
      if (workPlace) {
        put({
          ...commonForm,
          id: workPlace.id,
        })
      }
    } else {
      post(commonForm)
    }
  }
  const topTabs: TabsItem[] = [
    {
      title: 'Основные',
      badgeColor: hasValidationErrors(methods.formState.errors, ['todo'])
        ? 'red'
        : undefined,
      children: <Main />,
      id: '1',
    },
    {
      title: 'Классы',
      badgeColor: hasValidationErrors(methods.formState.errors, ['todo'])
        ? 'red'
        : undefined,
      children: <Classes />,
      id: '2',
    },
    {
      title: 'Гарантии и компенсации',
      badgeColor: hasValidationErrors(methods.formState.errors, ['todo'])
        ? 'red'
        : undefined,
      children: <Warranties />,
      id: '3',
    },
  ]

  return (
    <AddNewItemModalLayout
      {...additionalProps}
      handleCloseModal={handleCloseModal}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      isEdit={isEdit}
      titlePostfix="рабочее место"
      withStopPropagation
    >
      <TopTabsWrapper>
        <Tabs
          withNoUnmount
          items={topTabs}
          active={active}
          setActive={setActive}
          tabSize="small"
        />
      </TopTabsWrapper>
    </AddNewItemModalLayout>
  )
}

export default WorkPlaceModal
