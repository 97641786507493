import { FC } from 'react'
import * as React from 'react'
import LineFields from '@templates/AddNewItemModal/Content/LineFields'
import { ExternalLineWrapper } from '@templates/AddNewItemModal/Content/LineFields/styled'
import { Field } from '@templates/AddNewItemModal/Content'
import {
  checkboxField,
  textAreaField,
} from '@templates/AddNewItemModal/templates'
import { DescriptionWrapper } from '@modals/EmployeeEducationModal/styled'
import { ContentBeforeItemsWrapper } from '@templates/AddNewItemModal/Content/styled'

const WIDTH_MULTIPLIER = 3

const renderBlock = (lineTitle: string, prefix: string) => {
  const line11 = (prefix: string): Field[] => [
    checkboxField({
      name: `${prefix}_trebuemoe`,
      label: 'Требуемое',
    }),
    checkboxField({
      name: `${prefix}_facticheskoe`,
      label: 'Фактическое',
    }),
  ]
  const line12 = (prefix: string): Field[] => [
    textAreaField({
      name: `${prefix}_rekomendaciipootdihu`,
      placeholder: 'Основание',
      label: 'Основание',
      widthMultiplier: WIDTH_MULTIPLIER,
    }),
  ]

  return (
    <>
      <LineFields
        fields={line11(prefix)}
        lineTitle={lineTitle}
        lineTitleFontSize={18}
      />
      <LineFields fields={line12(prefix)} />
    </>
  )
}

const Warranties: FC = () => {
  return (
    <ExternalLineWrapper>
      <ContentBeforeItemsWrapper>
        <DescriptionWrapper>
          Гарантии и компенсации, предоставляемые работнику (работникам),
          занятым на данном рабочем месте.
        </DescriptionWrapper>
      </ContentBeforeItemsWrapper>
      {renderBlock(
        'Повышенная оплата труда работника (работников)',
        'povishennayaoplata'
      )}
      {renderBlock(
        'Ежегодный дополнительный оплачиваемый отпуск',
        'ejegodniiotpusk'
      )}
      {renderBlock(
        'Сокращенная продолжительность рабочего времени',
        'sokrasennayaprodolzitelnost'
      )}
      {renderBlock(
        'Молоко или другие равноценные пищевые продукты',
        'molokoilidrugie'
      )}
      {renderBlock(
        'Лечебно-профилактическое питание',
        'lechebnoprofilacticheskoepitanie'
      )}
      {renderBlock(
        'Проведение медицинских осмотров работников по результатам специальной оценки условий труда',
        'provedeniemedosmotrov'
      )}
      {renderBlock(
        'Право на досрочное назначение страховой пенсии',
        'pravonapensii'
      )}
    </ExternalLineWrapper>
  )
}

export default Warranties
