import { FC } from 'react'
import LineFields from '@templates/AddNewItemModal/Content/LineFields'
import { ExternalLineWrapper } from '@templates/AddNewItemModal/Content/LineFields/styled'
import { Field } from '@templates/AddNewItemModal/Content'
import { selectField } from '@templates/AddNewItemModal/templates'
import { Option } from '@organs/Select'

const DROPDOWN_WIDTH = 350

const Classes: FC = () => {
  const classesOptions: Option[] = [
    {
      label: 'class1',
      value: 'class1',
    },
  ]

  const line1: Field[] = [
    selectField({
      name: 'himicheskii',
      label: 'Химический',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: 'biologicheskii',
      label: 'Биологический',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: 'aerozoli',
      label: 'Аэрозоли преимущественно фиброгенного действия',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: 'shum',
      label: 'Шум',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
  ]

  const line2: Field[] = [
    selectField({
      name: 'infrazvuq',
      label: 'Инфразвук',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: 'ultrazvukobbsii',
      label: 'Ультразвук воздушный',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: 'vibraciaobsaa',
      label: 'Вибрация общая',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: 'vibracialocal',
      label: 'Вибрация локальная',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
  ]

  const line3: Field[] = [
    selectField({
      name: 'neioniziru',
      label: 'Неионизирующие излучения',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: 'ioniziru',
      label: 'Ионизирующие излучения',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: 'parametriclimata',
      label: 'Параметры микроклимата',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: 'parametrisvetovoisredi',
      label: 'Параметры световой среды',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
  ]

  const line4: Field[] = [
    selectField({
      name: 'tajestprocessa',
      label: 'Тяжесть трудового процесса',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: 'naprajonnostprocess',
      label: 'Напряженность трудового процесса',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: 'itogoviiclassut',
      label: 'Итоговый класс УТ',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
  ]

  return (
    <ExternalLineWrapper>
      <LineFields fields={line1} />
      <LineFields fields={line2} />
      <LineFields fields={line3} />
      <LineFields fields={line4} />
    </ExternalLineWrapper>
  )
}

export default Classes
